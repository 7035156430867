import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { useState, useEffect } from "react";
import { appWithTranslation } from "next-i18next";
import { Provider } from "urql";
import { ErrorBoundary } from "@appsignal/react";
import { UserProvider } from "@auth0/nextjs-auth0";
import { RecoilRoot } from "recoil";
import "react-datasheet-grid/dist/style.css";
import { useRouter } from "next/router";
import { LicenseInfo } from "@mui/x-license-pro";

// custom
import { urqlClient } from "lib/graphqlClient";
import { MeFieldFragment } from "graphql/generated";
import { MeContext } from "lib/auth";
import { DictContext } from "lib/dict";
import { InfoBarProvider } from "lib/infoBar";
import { AnalyticsTags, usePageView } from "lib/AnalyticsTags";
import "../styles/globals.css";
import { appsignal, appsignalTags } from "lib/appsignal";
import theme from "lib/theme";
import { LoadingProvider, Loading } from "lib/loadingContext";
import JotaiProvider from "lib/jotaiProvider";

// mui
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { CacheProvider, EmotionCache } from "@emotion/react";
import createEmotionCache from "lib/createEmotionCache";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

type DictContentType = Record<string, unknown>;

// MUI premiumのライセンスキーを設定
LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_PREMIUM_LICENSE_KEY || "no-license-key");

const GlobalHeader = dynamic(() => import("components/GlobalHeader"), { ssr: false });
const ChatWidgets = dynamic(() => import("components/chat/ChatWidgets"), { ssr: false });

// MUI premiumのライセンスキーを設定
LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_PREMIUM_LICENSE_KEY || "no-license-key");

const FallbackComponent = () => <div>Sorry... There was something wrong. Please reload or contact to administrator.</div>;

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  pageProps: {
    me: MeFieldFragment;
    dict: DictContentType;
    layout: string | null;
  };
}

const App: React.FC<MyAppProps> = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  usePageView(); // GA用のイベントフックを仕込む。
  const router = useRouter();

  // cometchatの初期化
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const CometChat = require("@cometchat-pro/chat").CometChat;
    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .autoEstablishSocketConnection(true)
      .setRegion("us")
      .build();
    CometChat.init(process.env.NEXT_PUBLIC_COMETCHAT_APP_ID, appSetting).then(
      () => {
        console.log("init cometchat ok");
      },
      (error: any) => {
        console.log("init cometchat fail", error);
      },
    );
  }, []);

  // https://github.com/vercel/next.js/blob/canary/examples/with-loading/pages/_app.tsx
  // を参考に。画面遷移時にLoadingするようにするための処理。
  const [pageLoading, setPageLoading] = useState(false);
  useEffect(() => {
    const handleStart = () => {
      setPageLoading(true);
    };

    const handleStop = () => {
      setPageLoading(false);
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleStop);
    router.events.on("routeChangeError", handleStop);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleStop);
      router.events.off("routeChangeError", handleStop);
    };
  }, [router]);

  const PageLoading: React.FC = () => {
    return (
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }} open={pageLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  let content = null;
  if (pageProps.layout === "none") {
    content = <Component {...pageProps} />;
  } else if (pageProps.layout === "noLoginHeader") {
    content = (
      <GlobalHeader noMenu>
        <PageLoading />
        <Component {...pageProps} />
      </GlobalHeader>
    );
  } else {
    content = (
      <GlobalHeader>
        <PageLoading />
        <Component {...pageProps} />
      </GlobalHeader>
    );
  }

  //エラーの時はGlobalHeader諸々スキップ
  if (router.pathname == "/404" || router.pathname == "/_error") return <Component {...pageProps} />;

  return (
    <ErrorBoundary instance={appsignal} tags={appsignalTags(pageProps.me, router.asPath)} fallback={() => <FallbackComponent />}>
      <Provider value={urqlClient}>
        <RecoilRoot>
          <CacheProvider value={emotionCache}>
            <ThemeProvider theme={theme}>
              <UserProvider>
                <MeContext.Provider value={pageProps.me}>
                  <DictContext.Provider value={{ dict: pageProps.dict, debug: router.query.debug_dict != undefined }}>
                    <JotaiProvider>
                      <CssBaseline />
                      <InfoBarProvider>
                        <LoadingProvider>
                          <AnalyticsTags />
                          <Loading />
                          <ChatWidgets />
                          {content}
                        </LoadingProvider>
                      </InfoBarProvider>
                    </JotaiProvider>
                  </DictContext.Provider>
                </MeContext.Provider>
              </UserProvider>
            </ThemeProvider>
          </CacheProvider>
        </RecoilRoot>
      </Provider>
    </ErrorBoundary>
  );
};

export default appWithTranslation(App as React.FC);
